export default {
  // chủ đề tin tức
  GROUP_NEW_GET_PAGING: 'GroupNew/get-paging',
  GROUP_NEW_CREATE: 'GroupNew/create',
  GROUP_NEW_EDIT: 'GroupNew/update',
  GROUP_NEW_DELETE: 'GroupNew/delete',
  // quản lý tin tức
  NEW_MANAGER_GET_PAGING: 'NewManager/get-paging',
  NEW_MANAGER_CREATE: 'NewManager/create',
  NEW_MANAGER_UPDATE: 'NewManager/update',
  NEW_MANAGER_DELETE: 'NewManager/delete',
  NEW_MANAGER_GET: 'NewManager/get-by-id',
  // kiểu tin tức
  TYPE_NEW: 'GroupNew/get-combobox',
  // ảnh tin tức
  IMG_NEW: 'NewManager/upload-avatar',
  // văn bản pháp lý
  DOCUMENT_LEGAL_GET_PAGING: 'LegalDocument/get-paging',
  DOCUMENT_LEGAL_DELETE: 'LegalDocument/delete',
  CREATE_LEGAL: 'LegalDocument/create',
  DOCUMENT_LEGAL_EDIT: 'LegalDocument/update',
  DOCUMENT_LEGAL_UPLOAD_FILE: '/LegalDocument/upload-file',
  DOCUMENT_LEGAL_DOWNLOAD_FILE: '/LegalDocument/download-file',
}
