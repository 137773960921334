<template lang="">
  <div>
    <b-modal
      :id="id"
      cancel-variant="outline-primary"
      ok-title="Lưu"
      cancel-title="Hủy bỏ"
      centered
      :title="title"
      @ok="accept($event)"
    >
      <validation-observer ref="rule">
        <b-form>

          <b-form-group>
            <label>Chủ đề <span class="required">(*)</span></label>
            <validation-provider
              #default="{ errors }"
              name="topic"
              rules="required"
              :custom-messages="topic"
            >
              <b-form-input
                v-model="dataInput.name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group>
            <label>Vị trí hiển thị</label>
            <b-form-input
              v-model="dataInput.position"
              type="number"
              :number="true"
            />
          </b-form-group>
          <!-- mô tả -->
          <b-form-group label-for="basicInput">
            <label for="textarea-default">Mô tả</label>
            <b-form-textarea
              id="textarea-default"
              v-model="dataInput.description"
              rows="3"
              no-resize
            />
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>

import {
  BButton, BModal, BForm, BFormInput, BFormGroup, BFormTextarea,
} from 'bootstrap-vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BFormTextarea,
  },
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    // eslint-disable-next-line vue/require-default-prop
    dataInput: {
      type: Object,
    },
  },
  data() {
    return {
      required,
      topic: {
        required: 'Chủ đề là bắt buộc',
      },
    }
  },
  methods: {
    accept(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('accept', this.dataInput)
        }
      })
    },
  },
}
</script>
<style lang="">

</style>
